.main-map {
  width: 100%;
  height: calc(100vh - 350px);
}

.event-map {
  flex: 1;
  height: calc(100vh - 300px);
}

.assets-map {
  width: 100%;
  height: calc(100vh - 350px);
}

.popup-row {
  white-space: nowrap;
}

.leaflet-popup-content {
  width: auto !important;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

input:not(:-webkit-autofill) {
  animation-name: auto !important;
}

.MuiInputBase-input.MuiOutlinedInput-input {
  height: auto;
}

.MuiAutocomplete-popupIndicator, .MuiAutocomplete-clearIndicator {
  width: 24px;
  height: 24px;
}
